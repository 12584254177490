p{
    margin:0;
}

h1{
    margin: 0   ;
}
.header{
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
}

.btn-container{
    display: flex;
    justify-content: center;   
    align-items: center;
    margin-top: 38rem;
}

.btn{
    color: #000;
    font-weight: bold;
    font-size: 1.5rem;
    padding:1rem 4rem;
    text-transform: uppercase;
    border: none;
    border-radius: 10rem;
    cursor: pointer;

}

.btn:hover{
    background-color: #D8532E;
    transition: .5s;

}
.popup-btn{
    display: inline;
   
}

.pop-btn{
    padding: 1rem 6rem;
}

.walletconnect{
    width: 99px;
    height:'96px'
}
.copyright-para{
    color: #fff;
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
}

.footer-icons{
    display: flex;
}

.footer-desc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    margin-top: 2rem;
}
.Img-container{
    width: 10%;
    margin: 0 10px;
}

.icon-img{
    width: 100%;
    background-color: #000;
    border-radius: 10rem;
    padding: 5px;
}

.icon-img:hover{
    background-color: #D8532E;
    transition: .5s;
}
@media (max-width: 575.98px) { 

.header{
    font-size: 1.2rem;
}
.footer-desc{ 
    margin-top: 4.8rem;
    justify-content: center;
    
}
.btn-container{

    margin-top: 19rem;
   
}
.btn{
       padding:1rem 5rem;
       font-size: 1.3rem;
}
.footer-icons{
    display: flex;
    justify-content: center;
}
.vedio{
    display: none;
}
 }

 @media (max-width: 991.98px) {  
    .App{
        background-image: url("./video/bg_image.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
        margin: 0;
    }
    .header{
        padding-top: 5rem;
        font-size: 1.2rem;
        
    }
    .footer-desc{ 
        margin-top: 4.8rem;
        
        justify-content: space-around;
        
    }
    .btn-container{     
        margin-top: 0rem; 
        margin-bottom: 10rem;  
        padding-top: 22rem;
       
    }
    .btn{
           padding:1rem 5rem;
           font-size: 1.3rem;
    }
    .footer-icons{
        /* margin-top: 20rem; */
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
 }
